import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
  Badge,
} from "@mui/material";
import Confetti from "react-confetti";
import TypographyMD from "../components/items/Typography";
import background from "../Assets/background.PNG";
import jackpot from "../Assets/jackpot.png";
import rectangle from "../Assets/rectangle.png";
import triangle from "../Assets/triangle.png";
import axios from "axios";
import url from "../url";
import { io } from "socket.io-client";
import { useLocation, useNavigate } from "react-router-dom";
import endpoint, { url_FE } from "../Endpointurl";
import { Schedule } from "@mui/icons-material";
import ButtonMD from "../components/items/ButtonMD";
import ModalAdd from "../components/items/Modal";
import ModalWarning from "../components/items/ModalWarning";
import Inputfield from "../components/items/Inputfield";
import { useFormik } from "formik";
import * as yup from "yup";
import InputfieldCom from "../components/items/InputfieldCom";
import { useTranslation } from "react-i18next";
import toastAlert from "../components/toastAlert";

function CurrentGame() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [gameStatus, setGameStatus] = useState("");
  const [profiledetails, setProfiledetails] = useState("");
  const [balls, setBalls] = useState([]);
  const [game, setGame] = useState([]);
  const [selectedBall, setSelectedBall] = useState(null);

  const getBalls = () => {
    axios
      .get(`${url}contact_us/get_all_ball_images`)
      .then((response) => console.log(response.data.data))
      .catch((error) => {
        console.error("Error fetching data:", error);
        toastAlert("error","Something went wrong");

      
      });
  };

  // schedule game
  const [user_has_selcted_ball, setuser_has_selected_ball] = useState(false);

  const [user_current_selected_balls, setUser_current_selected_balls] =
    useState([]);
  const [user_can_select_ball_restarted, setUser_can_select_ball_restarted] =
    useState(false);
  const [restartedStatus, setRestartedStatus] = useState(false);
  const [restartedRound, setRestartedRound] = useState(0);
  const getScheduleGame = (details) => {
    var InsertAPIURL = `${url}game/get_scheduled_games?user_id=${details?.data?.user_id}`;
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    fetch(InsertAPIURL, {
      method: "GET",
      headers: headers,
      body: JSON.stringify(),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        if (response.data == null || undefined || response.data.length == 0) {
          navigate(`${endpoint}dashboard`);
        }
        // } else if (response.data[0].game_status == "waiting") {
        //     navigate(`${endpoint}waiting`);
        // } else if (response.data[0].game_status == "started") {
        //     navigate(`${endpoint}gamestarted`);
        // } else if (response.data[0].user_selcted_ball >= 1) {
        //     navigate(`${endpoint}pickball`);
        // }
        //  else if (response.data[0].game_status == "scheduled") {
        //     navigate(`${endpoint}restart`);
        // }
        else {
          console.log(response?.data[0]);
          setGame(response?.data[0]);
          setStatus(response?.data[0]);
          setGameStatus(response?.data[0]?.game_status);
          setuser_has_selected_ball(response?.data[0]?.user_participated);
          console.log(response?.data[0]?.user_selected_ball_details);
          if (
            response?.data[0]?.user_participated === "true" ||
            response?.data[0]?.user_participated === true
          ) {
            setUser_current_selected_balls(
              response?.data[0]?.user_selected_ball_details
            );
            // if response?.data[0]?.user_selected_ball_details has only one row then set thaty id to some state .. else get last index id set to state
            let ballDetails = response?.data[0]?.user_selected_ball_details;
            if (ballDetails && ballDetails.length > 0) {
              // If there's only one row, set its ID to state
              if (ballDetails.length === 1) {
                setGameusersID(ballDetails[0].game_user_id);
              } else {
                // If there are multiple rows, set the last row's ID to state
                setGameusersID(
                  ballDetails[ballDetails.length - 1].game_user_id
                );
              }
            }
          }

          setRestartedStatus(response?.data[0]?.restartedStatus);
          setRestartedRound(response?.data[0]?.restartedRound);
          let ballData = response.data[0].ball_counts_participants;
          const formattedBalls = Object.keys(ballData).map((key, index) => ({
            id: index + 1,
            imageUrl: ballData[key].imageUrl,
            count: ballData[key].count,
          }));
          //   setGameusersID
          let user;
          // Check if restarted status is true and if any object in `user_selected_ball_details`
          // has round_no equal to the game's restarted_round_no
          console.log("AAAA");
          console.log(response?.data[0]?.restartedStatus);
          let ballDetails1 = response?.data[0]?.user_selected_ball_details;

          if (
            response?.data[0]?.restartedStatus === true &&
            ballDetails1?.length > 0
          ) {
            console.log("VVV");
            const restartedRound = response?.data[0]?.restartedRound;
            console.log("YYYY");
            console.log(restartedRound);

            // Check if any ball detail has `round_no` equal to `restarted_round`
            const hasMatchingRound = ballDetails1.some(
              (ball) => parseInt(ball.round) === parseInt(restartedRound)
            );
            console.log(ballDetails1);

            if (hasMatchingRound) {
              console.log("true");
              setUser_can_select_ball_restarted(false);
            } else {
              console.log("false");
              setUser_can_select_ball_restarted(true);
            }
          } else {
            // If the game is restarted but the user has not selected any ball
            console.log("No balls selected or the game has not restarted");
            setUser_can_select_ball_restarted(true); // Allow the user to select a ball
          }
          
          setBalls(formattedBalls);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastAlert("error","Failed to load scheduled games");

       
      });
  };

  const [opensuccess, setOpensuccess] = useState(false);

  // low balance
  const [openlowbalance, setOpenlowbalance] = useState(false);
  const handleopenlowbalance = (data) => {
    setOpenlowbalance(true);
  };

  // deposit
  const [openmodaldeposit, setOpenmodaldeposit] = useState(false);
  const handleopenmodaldeposit = (data) => {
    setOpenmodaldeposit(true);
    setOpenlowbalance();
  };

  const validationSchema = yup.object({
    amount: yup.string().required("Amount is required"),
  });
  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: validationSchema,

    onSubmit: (values, { resetForm }) => {
      console.log(values);

      setLoading(true);
      setTimeout(() => {
        const paymentData = {
          user_id: 100653,
          // game_id: 10242,
          items: [
            {
              name: "Rimsha",
              sku: "item",
              price: values.amount,
              currency: "USD",
              quantity: 1,
            },
          ],
          amount: {
            currency: "USD",
            total: values.amount,
          },
          description: "This is the payment description.",
          redirect_urls: {
            //
            return_url: `${url_FE}${endpoint}success`,
            cancel_url: `${url_FE}${endpoint}cancel`,
          },
        };

        fetch(`${url}pay`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            console.log("response");
            console.log(response);
            return response.json();
          })
          .then((data) => {
            console.log("data");

            console.log(data);
            console.log(data.approval_url);

            setTimeout(() => {
              window.location.href = data.approval_url;
              setLoading(false);
              // handleOpensuccess();

              localStorage.setItem("deposit_amount", values.amount);

              // var InsertAPIURL = `${url}create_payment_paypal-db-wallet`
              // var headers = {
              //     'Accept': 'application/json',
              //     'Content-Type': 'application/json',
              // };
              // var Data = {
              //     "user_id": profiledetails?.data?.user_id,
              //     "amount": values.amount
              // };
              // fetch(InsertAPIURL, {
              //     method: 'POST',
              //     headers: headers,
              //     body: JSON.stringify(Data),
              // })
              //     .then(response => response.json())
              //     .then(response => {
              //         console.log(response);
              //     }
              //     )
              //     .catch(error => {
              //         setLoading(false);
              //         toast.error(error, {
              //             position: toast.POSITION.BOTTOM_CENTER
              //         });
              //     });
            }, 3000);
          })
          .catch((error) => {
            setLoading(false);
        toastAlert("error","Somehting went wrong");

           

            console.log(
              "There has been a problem with your fetch operation:",
              error
            );
          });
      }, 2000);
    },
  });

  useEffect(() => {
    // Initialize the socket connection
    const socket = io(url);

    // Define the message listener
    const messageListener = (msg) => {
      console.log("msg", msg);
      setStatus(msg);
      const details = JSON.parse(localStorage.getItem("profiledetails"));

      switch (msg.status) {
        case "created":
          console.log("game-created"); // show triangle screen
          //   navigate(`${endpoint}playgame`);
          //   navigate(`${endpoint}current_game`);
          setLoader(true);

          setTimeout(() => {
            getScheduleGame(details);

            setLoader(false);
          }, 2000);
          break;
        case "waiting":
          console.log("game-status-change"); // show waiting screen ss in phone if status is waiting
          //   navigate(`${endpoint}waiting`);
          setLoader(true);

          setTimeout(() => {
            getScheduleGame(details);

            setLoader(false);
          }, 2000);
          break;
        case "started":
          console.log("game-started"); // if status is started then show animation
          //   navigate(`${endpoint}gamestarted`);
          setLoader(true);

          setTimeout(() => {
            getScheduleGame(details);

            setLoader(false);
          }, 2000);
          break;
        case "result-anounced":
          console.log("result-anounced");
          navigate(`${endpoint}winner`);
          break;
        case "restart":
          console.log("game-restart"); // show restart game screen ss in phone
         
        toastAlert("success","Game has Restarted");

          setTimeout(() => {
            // navigate(`${endpoint}playgame`);
            window.location.reload();
          }, 2000);
          break;
        case "added-participants":
          console.log("added-participants");
          getScheduleGame(details);
          break;
        case "deleted":
          console.log("game-deleted");
          navigate(`${endpoint}dashboard`);
          break;
        case "scheduled":
          console.log("game-scheduled");
          navigate(`${endpoint}current_game`);
          break;
        default:
          console.log("Unknown status");
      }

      console.log(":ddggfgf");
    };

    // Set up the socket event listener
    socket.on("received-data", messageListener);

    // Cleanup function to remove the message listener and disconnect socket
    return () => {
      socket.off("received-data", messageListener);
      socket.disconnect();
    };
  }, [status]);

  useEffect(() => {
    const details = JSON.parse(localStorage.getItem("profiledetails"));
    if (details) {
      setProfiledetails(details);
    }

    getBalls();
    try {
      getScheduleGame(details);
    } catch (error) {
      toastAlert("error","Failed to load scheduled games. Please try again.");

    
    }
  }, []);

  const rows = [1, 2, 3, 4, 5]; // Number of balls per row

  let ballIndex = 0;

  const disabledBalls = [8, 9];

  const [selected, setSelected] = useState("");
  const [gameusersID, setGameusersID] = useState("");

  const [selectedballUpdate, setSelectedballUpdate] = useState();
  const [modalConfirmUpadteBall, setModalConfirmUpadteBall] = useState(false);
  const [gameRestartedModal, setGameRestartedModal] = useState(true);
  const handleBallChange = (ball) => {
    setLoading(true);
    if (!disabledBalls.includes(ball.id)) {
      setSelectedBall(ball.id);
      // console.log(ball);
      // setOpensuccess(true);
      setSelected(ball.imageUrl);

      localStorage.setItem("selectedBall", JSON.stringify(ball));

      var InsertAPIURL = `${url}game_user/participate`;
      var headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      var Data = {
        game_users_id: gameusersID,
        winning_ball: ball.id,
      };
      fetch(InsertAPIURL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(Data),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setLoading(false);
          console.log("update ball", response);
          setModalConfirmUpadteBall(false);
          getScheduleGame(profiledetails);
      toastAlert("success","Ball Updated Successfully!");

         
        })
        .catch((error) => {
          setModalConfirmUpadteBall(false);
          setLoading(false);
          toastAlert("error","Something went wrong!");

         
        });
    }
  };
  const handleBallClick = (ball) => {
    // setOpensuccess(true);

    if (!disabledBalls.includes(ball.id)) {
      if (gameStatus === "scheduled") {
        // if()
        if (restartedStatus === true) {
          console.log("restartedRound");

          console.log(restartedRound);
          if (user_can_select_ball_restarted) {
            setSelectedBall(ball.id);
            console.log(ball);
            setOpensuccess(true);
            setSelected(ball.imageUrl);
          } else {
            // console.log("update alert ");
            // console.log(ball);
            // setModalConfirmUpadteBall(true);
            // // handleBallClick(ball)
            // setSelectedballUpdate(ball);
          }

          //   console.log(user_current_selected_balls.length);
          //   if (user_current_selected_balls.length === 0) {
          //     setSelectedBall(ball.id);
          //     // console.log(ball);
          //     setOpensuccess(true);
          //     setSelected(ball.imageUrl);
          //     //  update alerrt
          //   } else {
          //     console.log("update alert ");
          //     console.log(ball);
          //     // setModalConfirmUpadteBall(true)
          //     // // handleBallClick(ball)
          //     // setSelectedballUpdate(ball)
          //   }
        } else {
          if (
            user_has_selcted_ball === "true" ||
            user_has_selcted_ball === true
          ) {
            // setModalConfirmUpadteBall(true);
            // // handleBallClick(ball)
            // setSelectedballUpdate(ball);
          } else {
            setSelectedBall(ball.id);
            // console.log(ball);
            setOpensuccess(true);
            setSelected(ball.imageUrl);
            getScheduleGame(profiledetails);

            localStorage.setItem("selectedBall", JSON.stringify(ball));
          }
        }
      }
    }
  };

  const handleDeductAmount = (ball) => {
    // console.log(selectedBall);
    setLoading(true);
    setTimeout(() => {
      var InsertAPIURL = `${url}create_payment_paypal-db-v1`;
      var headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      var Data = {
        user_id: profiledetails?.data?.user_id,
        game_id: game.game_id,
        amount: game.entry_fee,
      };
      fetch(InsertAPIURL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(Data),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          // setLoading(false);
          console.log(response);
          if (response.error) {
            if (response.message == "Game User Already Exist") {
              setLoading(false);
              toastAlert("error",`${response.message}, pick your ball`);
              setOpensuccess(false);

              //   setTimeout(() => {
              //     navigate(`${endpoint}current_game`);
              //   }, 2000);
            } else {
              setLoading(false);
              handleopenlowbalance();
              setOpensuccess(false);
            }
          } else {
            var InsertAPIURL = `${url}game_user/participate`;
            var headers = {
              Accept: "application/json",
              "Content-Type": "application/json",
            };
            var Data = {
              game_users_id: response.data[0].game_users_id,
              winning_ball: selectedBall,
            };
            fetch(InsertAPIURL, {
              method: "POST",
              headers: headers,
              body: JSON.stringify(Data),
            })
              .then((response) => response.json())
              .then((response) => {
                console.log(response);
                setLoading(false);
                console.log(response);

                if (response.error) {
                  setOpensuccess(false);

                 
                  toastAlert("error",response.message);
                } else {
                  localStorage.setItem(
                    "game_users_id",
                    JSON.stringify(response.data[0].game_users_id)
                  );
                  toastAlert("success","Enter Fee submitted successfully!")
                  //   navigate(`${endpoint}entryconfirm`);
                  setShowConfetti(true);
                  setOpensuccess(false);
                  setTimeout(() => {
                    setShowConfetti(false);
                  }, 5000);

                  setLoading(false);
                  getScheduleGame(profiledetails);
                }
              })
              .catch((error) => {
                setOpensuccess(false);

                setLoading(false);
                toastAlert("error","Something went wrong!");
               
              });
          }
        })
        .catch((error) => {
          setLoading(false);
          toastAlert("error","Something went wrong!");
         
        });
    }, 3000);
  };
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      getScheduleGame(profiledetails); // replace with your target route
    }, 1000); // 3000 milliseconds = 3 seconds

    return () => clearTimeout(timer); // cleanup the timer
  }, []);

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 2000); // 2 seconds

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <>
      <Sidebar
        componentData={
          <Box
            sx={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              width: "100%",
              height: "100vh",
              overFlow: "hidden",
            }}
          >
            <Container>
              {loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
                  {showConfetti && (
                    <Confetti
                      width={window.innerWidth} // Full-screen width
                      height={window.innerHeight} // Full-screen height
                      numberOfPieces={500} // Number of confetti pieces
                      gravity={0.3} // Controls how fast confetti falls (lower means slower)
                      wind={0.01} // Controls horizontal drift
                    />
                  )}
                  {/* </div> */}
                  <Grid container spacing={0}>
                    <Grid xs={12}>
                      <Box sx={{ display: { xs: "none", md: "block" } }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {gameStatus === "scheduled" ? (
                            <>
                              {/* {} */}
                              {restartedStatus === true ||
                              restartedStatus === "true" ? (
                                <>
                                  <Typography
                                    variant="h6"
                                    color="#F5BC01"
                                    fontFamily="Pacifico"
                                    fontSize="57px"
                                    mt={1}
                                    mb={1}
                                  >
                                    {t("Restart Game")}
                                  </Typography>
                                  {user_can_select_ball_restarted ? (
                                    <>
                                      {" "}
                                      <TypographyMD
                                        variant="paragraph"
                                        label="🎯 Boost your chances! "
                                        color="#000000"
                                        marginLeft={0}
                                        fontFamily="Rubik"
                                        fontSize="25px"
                                        fontWeight={450}
                                        align="right"
                                      />
                                      <TypographyMD
                                        variant="paragraph"
                                        label=" Pick another ball by paying the entry fee! 💰"
                                        color="#000000"
                                        marginLeft={0}
                                        fontFamily="Rubik"
                                        fontSize="25px"
                                        fontWeight={450}
                                        align="right"
                                      />{" "}
                                    </>
                                  ) : null}
                                </>
                              ) : (
                                <Typography
                                  variant="h6"
                                  color="#F5BC01"
                                  fontFamily="Pacifico"
                                  fontSize="57px"
                                  mt={1}
                                  mb={1}
                                >
                                  {t("Play Game")}
                                </Typography>
                              )}

                              {user_has_selcted_ball === true ? (
                                <>
                                  <TypographyMD
                                    variant="paragraph"
                                    label="SELECTED BALL:"
                                    color="#000000"
                                    marginLeft={0}
                                    fontFamily="Rubik"
                                    fontSize="25px"
                                    fontWeight={450}
                                    align="right"
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignContent: "center",
                                    }}
                                  >
                                    {user_current_selected_balls.map((item) => (
                                      <>
                                        {/* {item?.count > 0 ?
                <> */}
                                        &nbsp;{" "}
                                        <img
                                          src={item?.ball_image}
                                          alt="..."
                                          style={{
                                            width: "5vh",
                                            height: "5vh",
                                          }}
                                        />
                                        {/* </>
                :
                <></>
            } */}
                                      </>
                                    ))}
                                  </div>
                                </>
                              ) : (
                                <TypographyMD
                                  variant="paragraph"
                                  label="Pick your winning ball! 🏆🔮"
                                  color="#000000"
                                  marginLeft={0}
                                  fontFamily="Rubik"
                                  fontSize="25px"
                                  fontWeight={450}
                                  align="right"
                                />
                              )}
                            </>
                          ) : null}

                          {gameStatus === "waiting" ? (
                            <>
                              <Typography
                                variant="h6"
                                color="#F5BC01"
                                fontFamily="Pacifico"
                                fontSize="57px"
                                mt={1}
                                mb={1}
                              >
                                {t("Waiting ...")}
                              </Typography>
                              <TypographyMD
                                variant="paragraph"
                                label="Game in Waiting. Starting soon"
                                color="#000000"
                                marginLeft={0}
                                fontFamily="Rubik"
                                fontSize="25px"
                                fontWeight={450}
                                align="right"
                              />
                              {user_has_selcted_ball === true ? (
                                <>
                                  <TypographyMD
                                    variant="paragraph"
                                    label="SELECTED BALL:"
                                    color="#000000"
                                    marginLeft={0}
                                    fontFamily="Rubik"
                                    fontSize="25px"
                                    fontWeight={450}
                                    align="right"
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignContent: "center",
                                    }}
                                  >
                                    {user_current_selected_balls.map((item) => (
                                      <>
                                        {/* {item?.count > 0 ?
                <> */}
                                        &nbsp;{" "}
                                        <img
                                          src={item?.ball_image}
                                          alt="..."
                                          style={{
                                            width: "5vh",
                                            height: "5vh",
                                          }}
                                        />
                                        {/* </>
                :
                <></>
            } */}
                                      </>
                                    ))}
                                  </div>
                                </>
                              ) : null}
                            </>
                          ) : null}
                          {gameStatus === "started" ? (
                            <>
                              <Typography
                                variant="h6"
                                color="#F5BC01"
                                fontFamily="Pacifico"
                                fontSize="57px"
                                mt={1}
                                mb={1}
                              >
                                {t("Game started")}
                              </Typography>

                              <TypographyMD
                                variant="paragraph"
                                label="Get ready, results will be announced soon! ⌛🤞"
                                color="#000000"
                                marginLeft={0}
                                fontFamily="Rubik"
                                fontSize="25px"
                                fontWeight={450}
                                align="right"
                              />
                              {user_has_selcted_ball === true ? (
                                <>
                                  <TypographyMD
                                    variant="paragraph"
                                    label="SELECTED BALL:"
                                    color="#000000"
                                    marginLeft={0}
                                    fontFamily="Rubik"
                                    fontSize="25px"
                                    fontWeight={450}
                                    align="right"
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignContent: "center",
                                    }}
                                  >
                                    {user_current_selected_balls.map((item) => (
                                      <>
                                        {/* {item?.count > 0 ?
                <> */}
                                        &nbsp;{" "}
                                        <img
                                          src={item?.ball_image}
                                          alt="..."
                                          style={{
                                            width: "5vh",
                                            height: "5vh",
                                          }}
                                        />
                                        {/* </>
                :
                <></>
            } */}
                                      </>
                                    ))}
                                  </div>
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </div>{" "}
                      </Box>
                    </Grid>

                    <Grid xs={12} sm={12} md={5}>
                      {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '75vh' }}> */}

                      <Stack ml={{ xs: 0, md: 15 }} mt={{ xs: 0, md: 5 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Stack mt={3.5}>
                            <Box
                              sx={{
                                position: "relative",
                                ml: { xs: 5, md: 0 },
                                width: { xs: "80%", md: "100%" },
                                height: "70px",
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              <Box
                                component="img"
                                src={rectangle}
                                alt="Rectangle"
                                sx={{
                                  width: "100%",
                                  height: { xs: "80&", md: "100%" },
                                  margin: 0,
                                  padding: 0,
                                }}
                              />
                              <Box
                                component="img"
                                src={jackpot}
                                alt="Jackpot Icon"
                                sx={{
                                  position: "absolute",
                                  top: { xs: "-30%", md: "-52%" },
                                  left: "-10%",
                                  width: { xs: "30%", md: "30%" },
                                  margin: 0,
                                  padding: 0,
                                }}
                              />
                              <Typography
                                variant="h5"
                                sx={{
                                  position: "absolute",
                                  top: { xs: "30%", md: "50%" },
                                  left: "25%",
                                  fontSize: { xs: "15px", md: "25px" },
                                  fontFamily: "Rubik",
                                  fontWeight: 550,
                                  transform: "translateY(-50%)",
                                  color: "#000000",
                                  textAlign: "left",
                                }}
                              >
                                Jackpot
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{
                                  position: "absolute",
                                  top: { xs: "30%", md: "50%" },
                                  right: "5%",
                                  fontWeight: 550,
                                  fontSize: { xs: "15px", md: "25px" },
                                  fontFamily: "Rubik",
                                  transform: "translateY(-50%)",
                                  color: "#000000",
                                  textAlign: "right",
                                }}
                              >
                                ${" "}
                                {game?.jackpot == null || undefined ? (
                                  "$0"
                                ) : (
                                  <>{game?.jackpot}</>
                                )}
                              </Typography>
                            </Box>
                          </Stack>
                        </div>

                        <Box
                          sx={{
                            mt: { xs: 0, md: 5 },
                            p: 2,
                            borderRadius: "10px",
                            backgroundColor: "white",
                            boxShadow: "none",
                            border: "1px solid #F5BC01",
                            width: { xs: "100%", md: "100%" },
                          }}
                        >
                          {/* lg */}
                          <Grid
                            container
                            spacing={0}
                            sx={{ display: { xs: "none", md: "flex" } }}
                          >
                            <Grid xs={6} md={6}>
                              <Stack direction="column">
                                <TypographyMD
                                  variant="paragraph"
                                  label="Participants"
                                  color="#000000"
                                  marginLeft={0}
                                  fontFamily="Rubik"
                                  fontSize="20px"
                                  fontWeight={500}
                                  align="left"
                                />

                                <TypographyMD
                                  variant="paragraph"
                                  label="Entry Fees"
                                  color="#000000"
                                  marginLeft={0}
                                  fontFamily="Rubik"
                                  fontSize="20px"
                                  fontWeight={500}
                                  align="left"
                                />

                                <TypographyMD
                                  variant="paragraph"
                                  label="Game ID"
                                  color="#000000"
                                  marginLeft={0}
                                  fontFamily="Rubik"
                                  fontSize="20px"
                                  fontWeight={500}
                                  align="left"
                                />
                              </Stack>
                            </Grid>

                            <Grid xs={6} md={6}>
                              <Stack direction="column">
                                <TypographyMD
                                  variant="paragraph"
                                  label={`${
                                    game?.total_participants == null ||
                                    undefined
                                      ? 0
                                      : game?.total_participants
                                  }`}
                                  color="#F5BC01"
                                  marginLeft={0}
                                  fontFamily="Rubik"
                                  fontSize="20px"
                                  fontWeight={500}
                                  align="right"
                                />

                                <TypographyMD
                                  variant="paragraph"
                                  label={`$${
                                    game?.entry_fee == null || undefined
                                      ? 0
                                      : game?.entry_fee
                                  }`}
                                  color="#F5BC01"
                                  marginLeft={0}
                                  fontFamily="Rubik"
                                  fontSize="20px"
                                  fontWeight={500}
                                  align="right"
                                />

                                <TypographyMD
                                  variant="paragraph"
                                  label={`# ${
                                    game?.game_id == null || undefined
                                      ? 0
                                      : game?.game_id
                                  }`}
                                  color="#F5BC01"
                                  marginLeft={0}
                                  fontFamily="Rubik"
                                  fontSize="20px"
                                  fontWeight={500}
                                  align="right"
                                />
                              </Stack>
                            </Grid>
                          </Grid>

                          {/* xs */}
                          <Grid
                            container
                            spacing={0}
                            sx={{ display: { xs: "flex", md: "none" } }}
                          >
                            <Grid xs={6} md={6}>
                              <Stack direction="column">
                                <TypographyMD
                                  variant="paragraph"
                                  label="Participants"
                                  color="#000000"
                                  marginLeft={0}
                                  fontFamily="Rubik"
                                  fontSize="15px"
                                  fontWeight={500}
                                  align="left"
                                />

                                <TypographyMD
                                  variant="paragraph"
                                  label="Entry Fees"
                                  color="#000000"
                                  marginLeft={0}
                                  fontFamily="Rubik"
                                  fontSize="15px"
                                  fontWeight={500}
                                  align="left"
                                />

                                <TypographyMD
                                  variant="paragraph"
                                  label="Game ID"
                                  color="#000000"
                                  marginLeft={0}
                                  fontFamily="Rubik"
                                  fontSize="15px"
                                  fontWeight={500}
                                  align="left"
                                />
                              </Stack>
                            </Grid>

                            <Grid xs={6} md={6}>
                              <Stack direction="column">
                                <TypographyMD
                                  variant="paragraph"
                                  label={`${
                                    game?.total_participants == null ||
                                    undefined
                                      ? 0
                                      : game?.total_participants
                                  }`}
                                  color="#F5BC01"
                                  marginLeft={0}
                                  fontFamily="Rubik"
                                  fontSize="15px"
                                  fontWeight={500}
                                  align="right"
                                />

                                <TypographyMD
                                  variant="paragraph"
                                  label={`$${
                                    game?.entry_fee == null || undefined
                                      ? 0
                                      : game?.entry_fee
                                  }`}
                                  color="#F5BC01"
                                  marginLeft={0}
                                  fontFamily="Rubik"
                                  fontSize="15px"
                                  fontWeight={500}
                                  align="right"
                                />

                                <TypographyMD
                                  variant="paragraph"
                                  label={`${
                                    game?.game_id == null || undefined
                                      ? 0
                                      : game?.game_id
                                  }`}
                                  color="#F5BC01"
                                  marginLeft={0}
                                  fontFamily="Rubik"
                                  fontSize="15px"
                                  fontWeight={500}
                                  align="right"
                                />
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                      </Stack>

                      {/* </div> */}
                    </Grid>

                    <Grid xs={12} sm={12} md={7}>
                      {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '75vh' }}> */}

                      <Box sx={{ display: { xs: "block", md: "none" } }}>
                        <Typography
                          variant="h6"
                          color="#F5BC01"
                          align="center"
                          fontFamily="Pacifico"
                          fontSize="27px"
                          mt={1}
                          mb={1}
                        >
                          {t("Play Game")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <TypographyMD
                            variant="paragraph"
                            label="Pick your winning ball! 🏆🔮"
                            color="#000000"
                            marginLeft={0}
                            fontFamily="Rubik"
                            fontSize="15px"
                            fontWeight={450}
                            align="center"
                          />
                        </div>
                      </Box>

                      {/* <Stack ml={{ xs: 0, sm: 15, md: 10 }}> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundImage: `url(${triangle})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: {
                              xs: "contain",
                              sm: "cover",
                              md: "cover",
                            },
                            backgroundPosition: "center", // Positioning the background image
                            width: {
                              xs: "100%",
                              sm: "55vh",
                              md: "70%",
                              lg: "70%",
                            },
                            height: { xs: "290px", sm: "330px", md: "485px" },
                            display: "flex", // Flexbox properties to center the content
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              ml: { xs: 2, md: 3 },
                              mt: { xs: -5, md: -9 },
                            }}
                          >
                            {rows.map((numBalls, rowIndex) => (
                              <Grid
                                container
                                justifyContent="center"
                                spacing={1}
                                key={rowIndex}
                                sx={{ marginBottom: 1 }}
                              >
                                {Array.from({ length: numBalls }).map(
                                  (_, colIndex) => {
                                    if (ballIndex >= balls.length) return null;
                                    const ball = balls[ballIndex++];
                                    const isDisabled = disabledBalls.includes(
                                      ball.id
                                    );

                                    return (
                                      <Grid
                                        item
                                        key={colIndex}
                                        sx={{ position: "relative" }}
                                        onClick={() => handleBallClick(ball)}
                                      >
                                        <Avatar
                                          src={ball.imageUrl}
                                          alt={`Ball ${ball.id}`}
                                          sx={{
                                            width: { xs: 30, sm: 40, md: 60 },
                                            height: {
                                              xs: 28,
                                              sm: 35,
                                              md: 55,
                                            },
                                            position: "relative",
                                            cursor: isDisabled
                                              ? "not-allowed"
                                              : "pointer",
                                            "&::after": isDisabled && {
                                              content: '""',
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              width: "100%",
                                              height: "100%",
                                              background:
                                                "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
                                              borderRadius: "50%",
                                            },
                                            filter: isDisabled
                                              ? "grayscale(100%)"
                                              : "none", // Apply grayscale to make it appear disabled
                                            pointerEvents: isDisabled
                                              ? "none"
                                              : "auto", // Disable interaction
                                          }}
                                        />
                                        <Box
                                          sx={{
                                            position: "absolute",
                                            bottom: "70%",
                                            left: "30%",
                                            transform: "translateX(-50%)",
                                            // background: "rgba(0, 0, 0, 0.7)",
                                            color: "#fff",
                                            borderRadius: "4px",
                                            padding: "0 4px",
                                            fontSize: {
                                              xs: "10px",
                                              md: "20px",
                                            },
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <Badge
                                            badgeContent={ball.count}
                                            color="error"
                                            overlap="circular"
                                            anchorOrigin={{
                                              vertical: "top",
                                              horizontal: "left",
                                            }}
                                          />
                                        </Box>
                                      </Grid>
                                    );
                                  }
                                )}
                              </Grid>
                            ))}
                          </Box>
                        </Box>
                      </div>
                      {/* </Stack> */}

                      {/* </div> */}
                    </Grid>
                  </Grid>
                </>
              )}
            </Container>
          </Box>
        }
      />

      {/* deduct amount */}
      <ModalWarning
        open={opensuccess}
        onClose={() => setOpensuccess(false)}
        errorImage={false}
        title="Ready to deduct game fee from your wallet?"
        // subheading={`User ${userdetails.status == "unblock" ? "block" : "unblock"} Successfully`}
        data={
          <ButtonMD
            variant="contained"
            title="Yes, sure"
            width="60%"
            type="submit"
            borderColor="orange"
            backgroundColor="orange"
            borderRadius="10px"
            disabled={loading}
            onClickTerm={handleDeductAmount}
          />
        }
      />

      {/* low Balance */}
      <ModalWarning
        open={openlowbalance}
        errorImage={true}
        onClose={() => setOpenlowbalance(false)}
        title="Low Balance Alert! ⚠️  Time to Top Up Your Wallet! 💰 "
        // subheading={`User ${userdetails.status == "unblock" ? "block" : "unblock"} Successfully`}
        data={
          <ButtonMD
            variant="contained"
            title="Deposit Amount"
            width="60%"
            type="submit"
            borderColor="orange"
            backgroundColor="orange"
            borderRadius="10px"
            disabled={loading}
            onClickTerm={handleopenmodaldeposit}
          />
        }
      />

      {/* deposit */}
      <ModalAdd
        open={openmodaldeposit}
        onClose={() => setOpenmodaldeposit(false)}
        title="Deposit Amount"
        data={
          <>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <div style={{ padding: 30 }}>
                  <InputfieldCom
                    autoFocus={false}
                    value={formik.values.amount}
                    // onChngeterm={(e) => formik.setFieldValue("amount", e.target.value)}
                    onChngeterm={(e) => {
                      const value = e.target.value;
                      if (value === "" || /^[a-zA-Z0-9]+$/.test(value)) {
                        formik.setFieldValue("amount", value);
                      }
                    }}
                    error={
                      formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                    type="number"
                    variant="outlined"
                    label=""
                    placeholder="Amount"
                    step="0.01"
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      marginTop: "5px",
                    }}
                  >
                    <ButtonMD
                      variant="contained"
                      title="Continue"
                      width="60%"
                      type="submit"
                      borderColor="orange"
                      backgroundColor="orange"
                      borderRadius="10px"
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
            </form>
          </>
        }
      />
      <ModalWarning
        open={modalConfirmUpadteBall}
        onClose={() => setModalConfirmUpadteBall(false)}
        title={t("Are you sure to update ball?")}
        errorImage={false}
        // subheading={`User ${userdetails.status == "unblock" ? "block" : "unblock"} Successfully`}
        data={
          <ButtonMD
            variant="contained"
            title={t("Yes, sure")}
            width="60%"
            type="submit"
            borderColor="orange"
            backgroundColor="orange"
            borderRadius="10px"
            disabled={loading}
            onClickTerm={() => handleBallChange(selectedballUpdate)}
          />
        }
      />

    </>
  );
}

export default CurrentGame;
