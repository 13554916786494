import React from "react";
import { Navigate } from "react-router-dom";

// Assuming you have an authentication state (from context or Redux)
const isAuthenticated = () => {
  return localStorage.getItem("username"); // Example: check token in localStorage
};

const PublicRoute = ({ children }) => {
  return !isAuthenticated() ? children : <Navigate to="/dashboard" />; // Redirect to dashboard if authenticated
};

export default PublicRoute;
