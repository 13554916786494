import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom'; 
import i18n from './multiLingual';
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId="366403309807-9eglv7mjfvct4oj1rjtmkr6jnb9mrkju.apps.googleusercontent.com">
      <React.StrictMode>
        <App />
        <Toaster
          
          position="top-center"
          toastOptions={{ className: "react-hot-toast",style: {
          zIndex: 2147483647,
        }, }}
        />
      </React.StrictMode>
    </GoogleOAuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
