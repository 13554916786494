import React from "react";
import { Navigate } from "react-router-dom";

// Assuming you have an authentication state (from context or Redux)
const isAuthenticated = () => {
  return localStorage.getItem("username"); // Example: check token in localStorage
};

const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/" />;
};

export default PrivateRoute;
