
import React, { useEffect, useRef, useState } from "react";
import ButtonMD from "../components/items/ButtonMD";
import "./scrollbar.css"

import endpoint from "../Endpointurl";
import url from "../url";
import ModalSuccess from "../components/items/ModalSuccess";
import { useNavigate } from "react-router-dom";
import toastAlert from "../components/toastAlert";
function Paypalsuccess() {
    const navigate = useNavigate();
    const [opensuccess, setOpensuccess] = useState(false);
    const handleSuccessCalled = useRef(false);
    const [profiledetails, setProfiledetails] = useState(null);
    const [amount, setAmount] = useState(null);

    // Fetch profile details and amount from localStorage
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const paymentId = urlParams.get('paymentId');
        const payerId = urlParams.get('PayerID');

        const details = JSON.parse(localStorage.getItem('profiledetails'));
        const depositAmount = localStorage.getItem('deposit_amount');
  // Redirect to portal/home if paymentId or payerId is missing
  if (!paymentId || !payerId) {
    navigate('/dashboard'); // Or navigate to your portal page
    return; // Early return to prevent further code execution
}
        if (details) setProfiledetails(details);
        if (depositAmount) setAmount(depositAmount);

        // Only call handleSuccess if paymentId and payerId exist and function hasn't been called yet
        if (paymentId && payerId && !handleSuccessCalled.current) {
            handleSuccessCalled.current = true;
            handleSuccess(details, depositAmount, paymentId, payerId);
        }
    }, []);

    const handleSuccess = async (details, depositAmount, paymentId, payerId) => {
        try {
            const executePaymentResponse = await fetch(`${url}execute-payment`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ paymentId, payerId }),
            });
            const data = await executePaymentResponse.json();

            if (!data.error) {
                console.log('Payment successful:', data);
                setOpensuccess(true);

                const insertAPIURL = `${url}create_payment_paypal-db-wallet`;
                const headers = {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                };
                const apiData = {
                    user_id: details?.data?.user_id,
                    amount: depositAmount,
                };

                const insertResponse = await fetch(insertAPIURL, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(apiData),
                });
                const insertData = await insertResponse.json();
                console.log(insertData);

                // You can handle success further or update state/UI here
                window.history.replaceState({}, document.title, window.location.pathname);

            } else {
                toastAlert("error", "Payment not approved");    
               
            }
        } catch (error) {
            console.error('Error executing payment:', error);
            toastAlert("error", "An error occurred while processing payment");
           
        }
    };

    return (
        <>
            <ModalSuccess
                open={opensuccess}
                onClose={() => setOpensuccess(false)}
                title="Amount Deposited Successfully"
                data={
                    <ButtonMD
                        variant="contained"
                        title="OK"
                        width="60%"
                        borderColor="orange"
                        backgroundColor="orange"
                        borderRadius="10px"
                        onClickTerm={() => navigate(`${endpoint}wallet`)}
                    />
                }
            />
        </>
    );
}

export default Paypalsuccess;
